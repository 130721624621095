<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="ml-1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ $t("users.sp.title") }}
                </li>
              </ol>
            </nav>
          </div>
          <div class="row ml-3 mb-3">
            <h6 class="ssp-24">{{ $t("users.sp.title") }}</h6>
          </div>
          <div class="row table-utilities">
            <div class="row ml-2 w-100">
              <div class="col-md-10 search-section">
                <div class="input-group">
                  <div class="input-group-prepend bg-white">
                    <span class="input-group-text" id="basic-addon1">
                      <div class="font-12">
                        <i class="ri-search-line ri-lg ri-sub"></i>
                      </div>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control search-input"
                    :placeholder="$t('users.sp.search')"
                    @input="setPage"
                    v-model="filterInput"
                  />
                </div>
              </div>
              <div class="col-md-2 pl-0">
                <button
                  class="btn table-button ssp-14-700 export-button h-100 w-100 d-flex align-items-center"
                  @click="handleExport"
                  :disabled="isLoading"
                >
                  <div class="font-12">
                    <div
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm text-success mr-1"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <i v-else class="ri-download-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.export") }}
                </button>
              </div>
            </div>
            <!-- <div class="row w-100 ml-2 mt-2">
              <div class="col-md-2 pl-0">
                <b-button
                  class="btn table-button ssp-14-700 h-100 d-flex align-items-center"
                  id="popover-button-variant"
                  href="#"
                  tabindex="0"
                >
                  <div class="font-12">
                    <i class="ri-filter-2-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.filter") }}
                </b-button>
              </div>
              <b-popover
                placement="right"
                target="popover-button-variant"
                variant="danger"
                triggers="focus"
              >
                <b-tabs content-class="mt-3">
                  <b-tab title="Nama SP" active>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 ssp-14"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                  <b-tab title="Nama TSS">
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 ssp-14"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                </b-tabs>
              </b-popover>
            </div> -->
          </div>
          <div class="table-borderless mx-3">
            <b-table-simple hover responsive style="overflow: hidden;">
              <b-thead class="bv-head">
                <b-tr>
                  <b-th
                    ><input
                      type="checkbox"
                      @click="selectAll"
                      v-model="allSelected"
                  /></b-th>
                  <b-th v-for="hd in $t('users.sp.headers')" :key="hd.id">
                    {{ hd }}
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody v-if="loading">
                <table-spinner cs="8" />
              </b-tbody>
              <b-tbody v-else-if="this.dataSP.length !== 0">
                <b-tr class="ssp-16-400" v-for="data in dataSP" :key="data.id">
                  <b-td
                    ><input
                      type="checkbox"
                      :value="data.id"
                      v-model="spIds"
                      @click="select"
                  /></b-td>
                  <b-td>{{ data.name }}</b-td>
                  <b-td>{{ data.ugdn }}</b-td>
                  <b-td>{{ data.email }}</b-td>
                  <b-td>{{ data.phone }}</b-td>
                  <b-td>{{ data.detail[0].regions.region }}</b-td>
                  <b-td style="width: 10%;" class="text-left">
                    <b-form-checkbox
                      v-model="data.isActive"
                      name="check-button"
                      @change="publishUser(data.id, data.isActive)"
                      disabled
                      switch
                    />
                  </b-td>
                  <!-- <b-td v-if="data.isActive">
                    <span class="badge badge-blue">
                      ACTIVE
                    </span></b-td
                  >
                  <b-td v-else
                    ><span class="badge badge-red">
                      INACTIVE
                    </span></b-td
                  > -->
                  <b-td class="action-column">
                    <div class="action-wrapper font-12">
                      <i
                        class="ri-eye-fill ri-lg ri-mid action-btn"
                        @click="onDetail(data.id)"
                      ></i>
                      <!-- <i
                        class="ri-whatsapp-fill ri-lg ri-mid action-btn"
                        @click="redirectWA(`+62${data.phone.slice(1)}`)"
                      ></i> -->
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
              <b-tbody v-else class="text-center text-italic">
                <b-tr>
                  <b-td colspan="8">{{ $t("global.empty") }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <div class="row align-items-center table-footer">
              <div class="col-sm-6 ssp-14-700">
                <!-- 1 - 6 of {{ this.totalData }} -->
                {{ $t("global.total_data") }} {{ this.totalData }}
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-7 align-self-center page-text">
                    {{ $t("global.page_on") }}
                  </div>
                  <div class="col float-right">
                    <div class="row">
                      <select
                        class="form-control pagination-pg"
                        v-model="currPage"
                      >
                        <option
                          v-for="index in totalPages"
                          :key="index.id"
                          :value="index"
                        >
                          {{ index++ }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col page-arrow ssp-14-700 ml-2">
                    <div class="row float-right">
                      <div
                        @click="redcPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div class="font-12" :class="{ orange: currPage > 1 }">
                          <i class="ri-arrow-left-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                      <div
                        @click="addPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div
                          class="font-12"
                          :class="{ orange: currPage < totalPages }"
                        >
                          <i class="ri-arrow-right-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <b-modal id="modal-sp" hide-footer>
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">{{ modalTitle }}</h5>
        <div class="pointer font-14" @click="close()">
          <i class="ri-close-circle-line ri-xl ri-mid"></i>
        </div>
      </template>

      <template #default="{ hide }">
        <div>
          <BaseInput
            :blabel="$t('users.label.sp')"
            type="text"
            v-model="details.name"
            disabled
          />
          <BaseInput
            :blabel="$t('users.label.ugdn')"
            type="text"
            :value="details.ugdn ? details.ugdn : '-'"
            disabled
          />
          <BaseInput
            :blabel="$t('users.label.email')"
            type="text"
            v-model="details.email"
            disabled
          />
          <BaseInput
            :blabel="$t('users.label.phone')"
            type="text"
            v-model="details.phone"
            disabled
          />
          <BaseInput :blabel="$t('tssreq.label.wk')">
            <section v-for="wk in details.detail" :key="wk.id">
              <table class="table table-borderless table-wk">
                <tbody>
                  <tr>
                    <td>{{ $t("tssreq.label.group") }}</td>
                    <td>:</td>
                    <td>{{ wk.groups !== null ? wk.groups.group : "-" }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("tssreq.label.country") }}</td>
                    <td>:</td>
                    <td>
                      {{ wk.countrys !== null ? wk.countrys.country : "-" }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("tssreq.label.region") }}</td>
                    <td>:</td>
                    <td>{{ wk.regions !== null ? wk.regions.region : "-" }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("tssreq.label.prov") }}</td>
                    <td>:</td>
                    <td>
                      {{ wk.province !== null ? wk.province.province : "-" }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("tssreq.label.kab") }}</td>
                    <td>:</td>
                    <td>
                      {{ wk.kabupaten !== null ? wk.kabupaten.kabupaten : "-" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
          </BaseInput>
          <BaseInput :blabel="$t('tssreq.label.pp')">
            <div class="detail-imgpreview">
              <img
                v-if="details.profile !== null"
                :src="url + details.profile"
                alt="profile"
              />
              <img
                v-else
                class="no-image"
                src="@/assets/img/no-image.png"
                alt="ktp"
              />
            </div>
          </BaseInput>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Card, BaseInput } from "@/components/index";
import axios from "axios";
import JwtService from "@/core/services/jwt.service";
import { RoleType } from "@/graphql/enum.type.js";
// import * as XLSX from "xlsx";
import { detailUser, Q_LIST_USER } from "@/graphql/queries";
import { M_PUBLISH_USER } from "@/graphql/sa";
import Axios from "axios";

export default {
  components: {
    Card,
    BaseInput
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      dataSP: [],
      dataTSS: [],
      url: JwtService.getURL(),
      optionRole: [RoleType.TSS, RoleType.SP],

      detailUser: JwtService.getDetail(),
      countryID: [],
      regionID: [],

      totalPages: null,
      currPage: 1,
      perPage: 10,
      totalData: null,
      filterInput: "",

      tssID: JwtService.getID(),

      modalTitle: null,
      isDisabled: null,
      isDetail: null,

      details: {},

      selected: [],
      allSelected: false,
      spIds: []
    };
  },
  apollo: {
    listUser: {
      query: () => Q_LIST_USER,
      variables() {
        return {
          isLoading: false,
          page: this.currPage,
          limit: this.perPage,
          keyword: this.filterInput,
          role: this.optionRole[1],
          country_id: [],
          region_id: [],
          prov_id: [],
          kab_id: [],
          kec_id: [],
          kel_id: [],
          tss_id: [JwtService.getID()]
        };
      },
      debounce: 500,
      result({ data }) {
        this.dataSP = data.listUser.users;
        this.totalPages = data.listUser.totalPage;
        this.totalData = data.listUser.total;
      },
      error(e) {
        this.catchError(e);
      },
      watchLoading(isLoading) {
        this.loading = isLoading;
      }
    }
  },
  methods: {
    // redirectWA(data) {
    //   window.open(`http://wa.me/${data}`, "_blank");
    // },
    publishUser(id, status) {
      return new Promise((resolve, reject) => {
        try {
          this.$apollo
            .mutate({
              mutation: M_PUBLISH_USER,
              variables: {
                id: id,
                isActive: status
              }
            })
            .then(() => {
              this.$apollo.queries.listUser.refetch();
              this.toastAlert("Data berhasil diupdate!");
              resolve();
            });
        } catch (e) {
          reject(e);
        }
      });
    },
    setPage() {
      this.currPage = 1;
    },
    addPage() {
      if (this.currPage < this.totalPages) {
        this.currPage++;
      }
    },
    redcPage() {
      if (this.currPage > 1) {
        this.currPage--;
      }
    },
    resetForm() {
      this.details = {};
    },
    onDetail(id) {
      this.resetForm();

      return new Promise((resolve, reject) => {
        try {
          this.$apollo
            .query({
              query: detailUser,
              variables: {
                id: id
              }
            })
            .then(res => {
              this.details = res.data.detailUser;
              this.modalTitle = "Detail Data Sales Promotor";
              this.isDisabled = true;
              this.isDetail = true;
              this.$bvModal.show("modal-sp");
              resolve();
            });
        } catch (e) {
          this.errorAlert(e);
          reject(e);
        }
      });

      // this.formDetail = {
      //   nama: data.name,
      //   nip: data.ugdn,
      //   hp: data.phone,
      //   email: data.email,
      //   kab: data.detail[0].kabupaten.kabupaten
      // };
    },
    selectAll() {
      this.spIds = [];
      if (!this.allSelected) {
        for (let i in this.dataSP) {
          this.spIds.push(this.dataSP[i].id);
        }
      }
    },
    select() {
      this.allSelected = false;
    },
    exportPetani() {
      axios
        .post(
          process.env.VUE_APP_URL_GRAPHQL + "/petani/excel",
          {
            wilayah_id: "1",
            jenis_wilayah: "Region"
          },
          {
            headers: {
              Authorization: "Bearer " + JwtService.getToken()
              // 'Content-Type': "application/json"
            },
            responseType: "blob"
          }
        )
        .then(async response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel"
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Laporan");
          document.body.appendChild(link);
          link.click();
        })
        .catch(err => {
          alert(err.message);
        });
    },
    async handleExport() {
      this.isLoading = true;
      await Axios.get(process.env.VUE_APP_URL_GRAPHQL + "/user/sp/excel", {
        responseType: "arraybuffer",
        headers: {
          Authorization: "Bearer " + JwtService.getToken(),
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          const type = response.headers["content-type"];
          const blob = new Blob([response.data], {
            type: type,
            encoding: "UTF-8"
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `salespromotor-${this.dateFr(
            new Date(),
            "DDMMYYYYHHMMss"
          )}.xlsx`;
          link.click();
        })
        .catch(e => {
          alert(e);
        });
      this.isLoading = false;
    }
    // async     async handleExport() {
    // this.isLoading = true;
    //   try {
    //     const result = await this.$apollo.query({
    //       query: Q_LIST_USER,
    //       variables: {
    //         page: this.currPage,
    //         limit: this.totalData,
    //         keyword: this.filterInput,
    //         role: this.optionRole[1],
    //         country_id: this.getCountryID(),
    //         region_id: this.getRegionID(),
    //         prov_id: this.getProvinceID(),
    //         kab_id: this.getKabupatenID(),
    //         kec_id: [],
    //         kel_id: [],
    //         tss_id: []
    //       }
    //     });
    //     const list = result.data.listUser.users;
    //     import("@/plugins/export-excel").then(excel => {
    //       const tHeader = ["Name", "UGDN", "Email", "Phone", "Status", "TSS"];
    //       const filterVal = ["name", "ugdn", "email", "phone", "status", "tss"];
    //       setTimeout(() => {
    //         list.map(a => {
    //           if (a.isActive) {
    //             a.status = "Active";
    //           } else {
    //             a.status = "Inactive";
    //           }
    //           if (a.detail[0].tss) {
    //             a.tss = a.detail[0].tss.name;
    //           }
    //           return a;
    //         });
    //         const data = this.formatJson(filterVal, list);
    //         excel.export_json_to_excel({
    //           header: tHeader,
    //           data,
    //           filename: this.$route.name,
    //           autoWidth: true,
    //           bookType: "xlsx"
    //         });
    //       }, 1500);
    //       this.limit = 10;
    //     });
    //   } catch (e) {
    //     this.errorAlert(e);
    //   }
    //   // this.currPage = 1;
    //   // setTimeout(() => {
    //   //   const filter = this.dataUsers.map(a => {
    //   //     return {
    //   //       Nama: a.name,
    //   //       Jabatan: a.role,
    //   //       UGDN: a.ugdn,
    //   //       Email: a.email,
    //   //       Phone: a.phone,
    //   //       "Status Aktif": a.isActive
    //   //     };
    //   //   });

    //   //   const data = XLSX.utils.json_to_sheet(filter);
    //   //   const wb = XLSX.utils.book_new();
    //   //   XLSX.utils.book_append_sheet(wb, data, "Data User");
    //   //   XLSX.writeFile(wb, "Users.xlsx");
    //   //   this.perPage = 10;
    //   // }, 2000);
    // }
  },
  created() {}
};
</script>

<style></style>
